import React from 'react';
import { useParams } from 'react-router-dom';
import AnimationRevealPage from "helpers/AnimationRevealPage.js"

// import EventLandingPage from "demos/EventLandingPage.js";
// import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
// import AgencyLandingPage from "demos/AgencyLandingPage.js";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

// import EventLandingPageImageSrc from "images/demo/EventLandingPage.jpeg";
// import HotelTravelLandingPageImageSrc from "images/demo/HotelTravelLandingPage.jpeg";
// import AgencyLandingPageImageSrc from "images/demo/AgencyLandingPage.jpeg";
// import SaaSProductLandingPageImageSrc from "images/demo/SaaSProductLandingPage.jpeg";
// import RestaurantLandingPageImageSrc from "images/demo/RestaurantLandingPage.jpeg";
import ServiceLandingPageImageSrc from "images/demo/ServiceLandingPage.jpeg";
// import HostingCloudLandingPageImageSrc from "images/demo/HostingCloudLandingPage.jpeg";


// import LoginPage from "pages/Login.js";
// import SignupPage from "pages/Signup.js";
// import PricingPage from "pages/Pricing.js";
// import AboutUsPage from "pages/AboutUs.js";
// import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

// import LoginPageImageSrc from "images/demo/LoginPage.jpeg";
// import SignupPageImageSrc from "images/demo/SignupPage.jpeg";
// import PricingPageImageSrc from "images/demo/PricingPage.jpeg";
// import AboutUsPageImageSrc from "images/demo/AboutUsPage.jpeg";
// import ContactUsPageImageSrc from "images/demo/ContactUsPage.jpeg";
// import BlogIndexPageImageSrc from "images/demo/BlogIndexPage.jpeg";
// import TermsOfServicePageImageSrc from "images/demo/TermsOfServicePage.jpeg";
// import PrivacyPolicyPageImageSrc from "images/demo/PrivacyPolicyPage.jpeg";

// import BackgroundAsImageHero from "components/hero/BackgroundAsImage.js";
// import IllustrationAndVideoHero from "components/hero/TwoColumnWithVideo.js";
// import IllustrationAndInputHero from "components/hero/TwoColumnWithInput.js";
import FeaturesAndTestimonialHero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import FullWidthWithImageHero from "components/hero/FullWidthWithImage.js";
// import BackgroundAsImageWithCenteredContentHero from "components/hero/BackgroundAsImageWithCenteredContent.js";
// import IllustrationAndPrimaryBackgroundHero from "components/hero/TwoColumnWithPrimaryBackground.js";

// import TwoPlansWithDurationSwitcherPricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
// import ThreePlansWithHalfPrimaryBackgroundPricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import ThreePlansPricing from "components/pricing/ThreePlans.js";

import ThreeColWithSideImageFeatures from "components/features/ThreeColWithSideImage.js";
// import TwoColWithButtonFeatures from "components/features/TwoColWithButton.js";
// import ThreeColSimpleFeatures from "components/features/ThreeColSimple.js";
// import ThreeColWithSideImageWithPrimaryBackgroundFeatures from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import TwoColVerticalWithButtonFeatures from "components/features/TwoColWithTwoFeaturesAndButtons.js";
// import TwoColHorizontalWithButtonFeatures from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import WithStepsAndImageFeatures from "components/features/TwoColWithSteps.js";
// import ThreeColumnDashedBorderFeatures from "components/features/DashedBorderSixFeatures";
import ThreeColCenteredStatsWithPrimaryBackgroundFeatures from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
// import WithStatsAndImageFeatures from "components/features/TwoColSingleFeatureWithStats.js";
// import WithStatsAndImage2Features from "components/features/TwoColSingleFeatureWithStats2.js";
// import VerticalWithAlternateImageAndTextFeatures from "components/features/VerticalWithAlternateImageAndText.js";

// import SliderCards from "components/cards/ThreeColSlider.js";
// import TrendingCards from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import PortfolioCards from "components/cards/PortfolioTwoCardsWithImage.js";
// import TabGridCards from "components/cards/TabCardGrid.js";
// import ProfileThreeColGridCards from "components/cards/ProfileThreeColGrid.js"
// import ThreeColContactDetailsCards from "components/cards/ThreeColContactDetails.js"

// import ThreeColSimpleWithImageBlog from "components/blogs/ThreeColSimpleWithImage.js";
// import ThreeColSimpleWithImageAndDashedBorderBlog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import PopularAndRecentPostsBlog from "components/blogs/PopularAndRecentBlogPosts.js";
// import GridWithFeaturedPostBlog from "components/blogs/GridWithFeaturedPost.js";

// import TwoColumnWithImageTestimonial from "components/testimonials/TwoColumnWithImage.js";
// import TwoColumnWithImageAndProfilePictureReviewTestimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import TwoColumnWithImageAndRatingTestimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import ThreeColumnWithProfileImageTestimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import SimplePrimaryBackgroundTestimonial from "components/testimonials/SimplePrimaryBackground.js";

// import SimpleWithSideImageFAQS from "components/faqs/SimpleWithSideImage.js";
// import SingleColFAQS from "components/faqs/SingleCol.js";
// import TwoColumnPrimaryBackgroundFAQS from "components/faqs/TwoColumnPrimaryBackground.js";

// import SimpleContactUsForm from "components/forms/SimpleContactUs.js";
// import SimpleSubscribeNewsletterForm from "components/forms/SimpleSubscribeNewsletter.js";
// import TwoColContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import TwoColContactUsFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";

// import GetStartedCTA from "components/cta/GetStarted.js";
// import GetStartedLightCTA from "components/cta/GetStartedLight.js";
// import DownloadAppCTA from "components/cta/DownloadApp.js";

// import SimpleFiveColumnFooter from "components/footers/SimpleFiveColumn.js";
import FiveColumnWithInputFormFooter from "components/footers/FiveColumnWithInputForm.js";
// import FiveColumnWithBackgroundFooter from "components/footers/FiveColumnWithBackground.js";
// import FiveColumnDarkFooter from "components/footers/FiveColumnDark.js";
// import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";


export const components = {
  landingPages: {
    ServiceLandingPage: {
      component: ServiceLandingPage,
      imageSrc: ServiceLandingPageImageSrc,
      url: "/components/landingPages/ServiceLandingPage",
    }
  },


  blocks: {
    Hero: {
      type: "Hero Section",
      elements: {
        FeaturesAndTestimonial: {
          name: "With Features And Customer Testimonial",
          component: FeaturesAndTestimonialHero,
          url: "/components/blocks/Hero/FeaturesAndTestimonial",
        }
      }
    },
    Features: {
      type: "Features Section",
      elements: {
        ThreeColWithSideImage: {
          name: "Three Column With Side Image",
          component: ThreeColWithSideImageFeatures,
          url: "/components/blocks/Features/ThreeColWithSideImage",
        },
        ThreeColCenteredStatsPrimaryBackground: {
          name: "Three Column With Centered Stats on Primary Background",
          component: ThreeColCenteredStatsWithPrimaryBackgroundFeatures,
          url: "/components/blocks/Features/ThreeColCenteredStatsPrimaryBackground",
        }
      }
    },
    Footer: {
      type: "Footers Section",
      elements: {
        FiveColumnWithInputForm: {
          name: "Five Column With Input Form",
          component: FiveColumnWithInputFormFooter,
          url: "/components/blocks/Footer/FiveColumnWithInputForm",
        }
      }
    }
  }
}

export default () => {
  const { type, subtype, name } = useParams()

  try {
    let Component = null;
    if(type === "blocks" && subtype) {
      Component= components[type][subtype]["elements"][name].component
      return <AnimationRevealPage disabled>
          <Component/>
        </AnimationRevealPage>
    }
    else
      Component= components[type][name].component

    if(Component)
      return <Component/>

    throw new Error("Component Not Found")
  }
  catch (e) {
    console.log(e)
    return <div>Error: Component Not Found</div>
  }
}
